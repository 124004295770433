import React, { useEffect } from 'react'
import Script from 'next/script'
import { FACEBOOK_PIXEL_ID } from '@/services/Configuration'
import { Facebook } from '@/services/Tracking/Facebook/Facebook'

const FacebookScript: React.FC = () => {
  useEffect(() => {
    Facebook.init()
  }, [])

  return (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        !function(){
          if(window.fbq){
            return;
          };
          window.fbq = function(){
            window.fbq.callMethod ?
            window.fbq.callMethod.apply(window.fbq,arguments):
            window.fbq.queue.push(arguments);
          };
          
          window.fbq.queue=[];
          if(!window._fbq){
            window._fbq = window.fbq
          };
          window.fbq.push = window.fbq;
          window.fbq.loaded=!0;
          window.fbq.version='2.0';
          /**/
        }();
        `,
        }}
      />
      <Script
        id="FacebookScript"
        strategy="afterInteractive"
        src="https://connect.facebook.net/en_US/fbevents.js"
      />
      <noscript>
        <img
          height="1"
          width="1"
          className="hidden"
          alt="Facebook Pixel"
          src={`https://www.facebook.com/tr?id=${FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  )
}

export default FacebookScript
