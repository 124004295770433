/* eslint-disable @typescript-eslint/naming-convention */
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { error, log } from '@/services/Log'
import { FACEBOOK_PIXEL_ID, SEARCH_ORIGIN, SHOP_ORIGIN } from '@/services/Configuration'
import type {
  FacebookPixelContentIds,
  FacebookPixelContents,
  FacebookPixelContentType,
  FacebookEventPayload,
  FacebookStandardEvent,
} from '@/types/ThirdPartyIntegrations/Facebook'

const CONTENT_TYPE_HEADER = { 'Content-Type': 'application/json' }
const BROWSER_AUTOMATED_HEADERS = {
  referer: `${SHOP_ORIGIN}/`,
  origin: SHOP_ORIGIN,
}

// Add in headers that the browser is not going to be able to provide, if needed.
const headers = process.browser
  ? CONTENT_TYPE_HEADER : { ...CONTENT_TYPE_HEADER, ...BROWSER_AUTOMATED_HEADERS }

// Pixel is the front-end APi that sends data to Meta, Docs:
// https://developers.facebook.com/docs/meta-pixel/reference

const hashing = async (data?: string) => {
  if (typeof data === 'string') {
    const [{ default: { sha256 } }] = await allPromisesWithRetries(() => [import('js-sha256')])
    return sha256(String(data || '').trim().toLowerCase())
  }
  return null
}

const parseUserData = async (email?: string, phone?: string) => {
  const [{ default: Cookies }] = await allPromisesWithRetries(() => [import('js-cookie')])
  return ({
    fbc: Cookies.get('_fbc'),
    fbp: Cookies.get('_fbp'),
    em: await hashing(email),
    ph: await hashing(phone),
    client_user_agent: navigator?.userAgent || '',
  })
}

const CLIENT_ACTION_SOURCE = 'website'

export type ConversionAPIPayload<T = undefined> = {
  user?: UserData | null,
  eventID?: string
} & T

export type PixelSafeguardHandler = (
  eventName: FacebookStandardEvent,
  eventPayload?: ConversionAPIPayload<FacebookEventPayload>,
  id?: { eventID: string }) => Promise<{ success: boolean }> | { success: boolean }

const Pixel: PixelSafeguardHandler = async (eventName, eventPayload = undefined, { eventID } = { eventID: '' }) => {
  log(`Facebook: Pixel invoked for ${eventName}`)
  if (typeof window !== 'object') {
    log('Facebook: error no Window')
    return { success: false }
  }
  const [{ v4: uuid }] = await allPromisesWithRetries(() => [import('uuid')])
  const event_id = eventID || (uuid())
  const user: UserData | null = eventPayload?.user || null

  try {
    if (eventPayload && user) {
      // eslint-disable-next-line no-param-reassign
      delete eventPayload.user
    }

    if (typeof window !== 'undefined' && typeof window.fbq === 'function') {
      log('Facebook: Window.FBQ Called')
      await window.fbq('track', eventName, eventPayload, { eventID: event_id })
    } else {
      error('Facebook: Either window is not an object or fbq is not a function')
    }

    if (typeof eventPayload === 'object') {
      const event_time = Math.floor((Date.now() / 1000))
      const event_source_url = window.location.hostname + window.location.pathname
      const userdata = await parseUserData(user?.email, user?.phone)
      const [{ default: Axios }] = await allPromisesWithRetries(() => [import('axios')])
      log('Facebook: Conversion API Request Sent', eventPayload)
      await Axios.post(`${SEARCH_ORIGIN}/sf/facebook/conversion/`, {
        event_id,
        event_name: eventName,
        event_time,
        event_source_url,
        user_data: userdata,
        action_source: CLIENT_ACTION_SOURCE,
        custom_data: eventPayload,
      },
      { headers })
    }
    return { success: true }
  } catch (err) {
    error(`Facebook: Pixel ${eventName} Failed ${err}`)
  }
  return { success: false }
}

type UserData = { email: string, phone: string }
type FacebookAddToCartPayload = ConversionAPIPayload<{
  content_ids: FacebookPixelContentIds,
  content_name: string,
  content_type?: FacebookPixelContentType,
  content_category: string,
  contents: FacebookPixelContents,
  currency?: string,
  value: number
}>
type AddToCartHandler = (
  item: FacebookAddToCartPayload
) => Promise<{ success: boolean }> | { success: boolean }

const addToCart: AddToCartHandler = ({
  content_ids,
  content_category,
  content_name,
  content_type = 'product',
  currency = 'USD',
  contents,
  value,
  user,
}) => Pixel('AddToCart', {
  content_ids,
  content_category,
  content_name,
  content_type,
  contents,
  currency,
  value,
  user,
})

type FacebookViewContentPayload = ConversionAPIPayload<{
  content_ids: FacebookPixelContentIds,
  content_name: string,
  content_type?: FacebookPixelContentType,
  content_category: string,
  contents: FacebookPixelContents,
  currency?: string,
  value: number
}>

type ViewContentHandler = (
  item: FacebookViewContentPayload
) => Promise<{ success: boolean }> | { success: boolean }
const viewContent: ViewContentHandler = ({
  content_ids,
  content_category = 'N/A',
  content_name,
  content_type = 'product',
  currency = 'USD',
  contents,
  value,
  user,
}) => Pixel('ViewContent', {
  content_ids,
  content_category,
  content_name,
  content_type,
  currency,
  contents,
  value,
  user,
})

type FacebookInitializeCheckoutPayload = ConversionAPIPayload<{
  content_ids: FacebookPixelContentIds,
  contents: FacebookPixelContents,
  content_type?: FacebookPixelContentType,
  currency?: string,
  value: number,
  num_items: number,
}>

type InitializeCheckoutHandler = (
  item: FacebookInitializeCheckoutPayload
) => Promise<{ success: boolean }> | { success: boolean }
const initializeCheckout: InitializeCheckoutHandler = ({
  content_ids,
  contents,
  content_type = 'product',
  currency = 'USD',
  value,
  num_items,
  user,
}) => Pixel('InitiateCheckout', {
  content_ids,
  contents,
  content_type,
  currency,
  num_items,
  value,
  user,
})

type TrackPurchasePayload = ConversionAPIPayload<{
  content_ids: FacebookPixelContentIds,
  order_id: string,
  content_type?: FacebookPixelContentType,
  contents: FacebookPixelContents,
  currency?: string,
  value: number,
  num_items: number,
}>

type TrackPurchaseHandler = (
  item: TrackPurchasePayload
) => Promise<{ success: boolean }> | { success: boolean }
const trackPurchase: TrackPurchaseHandler = ({
  content_ids,
  order_id,
  content_type = 'product',
  contents,
  value,
  currency = 'USD',
  num_items,
  user,
}) => Pixel('Purchase', {
  content_ids,
  order_id,
  content_type,
  contents,
  value,
  currency,
  num_items,
  user,
}, { eventID: order_id })

type CompleteRegistrationPayload = ConversionAPIPayload<{
  content_name: string,
  currency?: string,
  status?: boolean,
  value?: number,
  user_email? : string
}>

type CompleteRegistrationHandler = (
  item: CompleteRegistrationPayload
) => Promise<{ success: boolean }> | { success: boolean }
const completeRegistration: CompleteRegistrationHandler = ({
  content_name,
  status = true,
  currency = 'USD',
  user_email,
}) => Pixel('CompleteRegistration', {
  content_name,
  status,
  currency,
  user_email,
})

type SearchPayload = ConversionAPIPayload<{
  search_string: string,
  content_ids: FacebookPixelContentIds,
  contents: FacebookPixelContents,
  currency?: string,
  value?: number,
}>

type SearchHandler = (item: SearchPayload) => Promise<{ success: boolean }> | { success: boolean }
const search: SearchHandler = ({
  search_string,
  content_ids,
  contents,
  currency = 'USD',
  value = 0,
  user,
}) => Pixel('Search', {
  content_category: 'Product Search',
  content_ids,
  contents,
  currency,
  search_string,
  value,
  user,
})

type PixelHandler = () => Promise<{ success: boolean }> | { success: boolean }
const customizeProduct: PixelHandler = () => Pixel('CustomizeProduct')

type pageViewHandler = (
  id?: { eventID: string }
) => Promise<{ success: boolean }> | { success: boolean }
const pageView: pageViewHandler = (id) => Pixel('PageView', {}, id)

const init = () => {
  try {
    log(`Facebook: Pixel init typeof fbq = ${typeof window.fbq}`, { FACEBOOK_PIXEL_ID })
    window?.fbq?.('init', FACEBOOK_PIXEL_ID)
    pageView()
  } catch (err) {
    error(`Facebook: Failed to Initialize Facebook Pixel ${err}`)
  }
}

export const Facebook = {
  customizeProduct,
  search,
  completeRegistration,
  trackPurchase,
  initializeCheckout,
  viewContent,
  addToCart,
  pageView,
  init,
  hashing,
  parseUserData,
}

export default Facebook
